import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'

const StyledLink = styled(Link)`
    position: relative;
    overflow: hidden;
    margin-bottom: ${theme.space[3]};

    .image-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 300ms;

        img {
            object-fit: cover;
        }
    }

    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: ${theme.colors.black};
        opacity: 0.2;
    }

    ::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    :hover {
        .image-wrapper {
            transform: scale(1.1)
        }
    }


    p {
        font-size: 2.2rem;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 2;
        color: ${theme.colors.white} !important;
    }
`

export const ColumnLink = ({ children, ...props }) => {
    return <StyledLink {...props}>{children}</StyledLink>
}
