import { Link } from 'gatsby';
import React from 'react'

import styled from "styled-components";
import { theme } from "../../theme/theme";

const StyledButton = styled(Link)`
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 1rem 1.4rem;
  display: block;
  color: ${props => props.variant === 'primary' ? theme.colors.white : theme.colors.black};
  background-color: ${props => props.variant === 'primary' ? theme.colors.black : theme.colors.white};
  border: ${props => props.variant === 'primary' ? '' : `2px solid ${theme.colors.black}`};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  cursor: pointer;
  transition: background-color 300ms;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.variant === 'primary' ? theme.colors.blackAlpha[800] : theme.colors.blackAlpha[100]};
  }

  @media (min-width: 768px) {
    display: inline-block;
    margin-top: 2rem;
  }
`

export const Button = ({children, ...props}) => {
    return <StyledButton {...props}>{children}</StyledButton>
}
