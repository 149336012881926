import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { ColumnLink } from "../components/atoms/ColumnLink";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";
import { Button } from "../components/atoms/Button";

export const IndexPageTemplate = () => (
  <Container>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <Image src={`${withPrefix('/')}img/intro-image-2.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">Wildwood</Heading>
        <Text>Met een Wildwood haal je een stijlvolle en duurzame houten kerstboom in huis, die je zelf je favoriete vorm geeft. Een vorm die je gemakkelijk weer verandert. </Text>
        <Text>
          Jouw Wildwood komt uit een gelimiteerde oplage, exclusief gemaakt van duurzaam geproduceerd hout met het FSC-keurmerk.
        </Text>
        <Text>
          <Button variant="primary" to="/de-bomen">Bekijk onze bomen</Button>
          <Button variant="secondary" to="/contact">Contact & Bestel</Button>
        </Text>
      </Col>
    </Row>

    <Row justify="center" align="center" style={{paddingTop: theme.space['20'], paddingBottom: theme.space['10']}}>
      <Col align="center">
        <Heading as="p" variant="heading2">Stijlvol – Duurzaam – Exclusief </Heading>
        <Text>Een boom die blijft boeien en altijd de aandacht trekt.</Text>
      </Col>
    </Row>


    <Row style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={4}>
        <ColumnLink to="/over-ons">
          <Image src={`${withPrefix('/')}img/over-ons-landscape.jpg`} alt="Wildwood bomen" />
          <Heading as="p" variant="heading3">Over ons</Heading>
        </ColumnLink>
      </Col>
      <Col xs={12} md={4}>
        <ColumnLink to="/exclusief-en-stijlvol">
          <Image src={`${withPrefix('/')}img/wildwood-sfeervol.jpg`} alt="Wildwood bomen" />
          <Heading as="p" variant="heading3">Exclusief & Stijlvol</Heading>
        </ColumnLink>
      </Col>
      <Col xs={12} md={4}>
        <ColumnLink to="/duurzaam-en-sociaal">
          <Image src={`${withPrefix('/')}img/fsc-image.jpg`} alt="Wildwood bomen" />
          <Heading as="p" variant="heading3">Duurzaam & Sociaal</Heading>
        </ColumnLink>
      </Col>
    </Row>

  </Container>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
